// Landing ANIMATIONS CSS
.landing-animation {
    color: white;
    text-align: center;
}

.layer {
    background-size: contain !important;
    background-attachment: fixed;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    //Mobile Portrait
    @media (max-width: 450px) {
        background-size: contain !important;
        background-position: 0px 30px !important;
        background-attachment: fixed !important;
    }
    /* Mobile (Landscape) ----------- */
    @media (min-width: 568px) and (max-width: 812px) {
        background-attachment: fixed;
        background-size: contain !important;
        background-position: 50% 40px !important;
    }
    /* iPads (portrait) ----------- */
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
        background-position: 0 40px !important;
        background-size: contain !important;
    }
}

#layer-1 {
    background: url("../../images/layers/fos_desktop_landing_background_1.png") no-repeat center fixed;
    //Mobile Portrait
    @media (max-width: 450px) {
        background: url("../../images/layers/fos_mob_portrait_background_1.png") no-repeat center center;
    }
}

#layer-2 {
    background: url("../../images/layers/fos_desktop_landscape_landing_background_2.png") no-repeat center fixed;
    //Mobile Portrait    
    @media (max-width: 450px) {
        background: url("../../images/layers/fos_mob_portrait_background_2.png") no-repeat center center;
    }
}

#layer-3 {
    background: url("../../images/layers/fos_desktop_landscape_landing_background_3.png") no-repeat center fixed;
    //Mobile Portrait   
    @media (max-width: 450px) {
        background: url("../../images/layers/fos_mob_portrait_background_3.png") no-repeat center center;
    }
}

#layer-4 {
    background: url("../../images/layers/fos_desktop_landscape_landing_background_4.png") no-repeat center fixed;
    //Mobile Portrait   
    @media (max-width: 450px) {
        background: url("../../images/layers/fos_mob_portrait_background_4.png") no-repeat center center;
    }
}

#layer-5 {
    background: url("../../images/layers/fos_desktop_landscape_landing_background_5.png") no-repeat center fixed;
    //Mobile Portrait    
    @media (max-width: 450px) {
        background: url("../../images/layers/fos_mob_portrait_background_5.png") no-repeat center center;
    }
}

#layer-6 {
    background: url("../../images/layers/fos_desktop_landscape_landing_background_6.png") no-repeat center fixed;
    //Mobile Portrait   
    @media (max-width: 450px) {
        background: url("../../images/layers/fos_mob_portrait_background_6.png") no-repeat center center;
    }
}

@keyframes backgroundchangeFadeInOut {
    0% {
        opacity: 1;
    }
    17% {
        opacity: 1;
    }
    25% {
        opacity: 0;
    }
    92% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes backgroundchangeFadeInOut {
    0% {
        opacity: 1;
    }
    17% {
        opacity: 1;
    }
    25% {
        opacity: 0;
    }
    92% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.layers-wrapper div:nth-of-type(1) {
    animation-delay: 20s;
    -webkit-animation-delay: 20s;
}

.layers-wrapper div:nth-of-type(2) {
    animation-delay: 16s;
    -webkit-animation-delay: 16s;
}

.layers-wrapper div:nth-of-type(3) {
    animation-delay: 12s;
    -webkit-animation-delay: 12s;
}

.layers-wrapper div:nth-of-type(4) {
    animation-delay: 8s;
    -webkit-animation-delay: 8s;
}

.layers-wrapper div:nth-of-type(5) {
    animation-delay: 4s;
    -webkit-animation-delay: 4s;
}

.layers-wrapper div:nth-of-type(6) {
    animation-delay: 0;
    -webkit-animation-delay: 0;
}

.layers-wrapper div {
    animation-name: backgroundchangeFadeInOut;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 20s;
    -webkit-animation-name: backgroundchangeFadeInOut;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 20s;
}

// MAIN STATIC BACKGROUND
main {
    overflow: auto;
    background: url(../../images/fos_desktop_landing_background.png) no-repeat, url(../../images/stars_background_desktop.png) no-repeat #141414;
    background-position: center center;
    background-size: contain;
    background-attachment: fixed;
    @media (max-width: 450px) {
        background-position: 0px 30px !important;
        background-attachment: fixed !important;
        background-size: contain !important;
        background: url(../../images/layers/fos_mob_portrait_background_1.png) no-repeat;
    }
    /* iPads (portrait) ----------- */
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
        background-position: 0 40px;
    }
    @media screen and (min-device-width: 568px) and (max-device-width: 812px) {
        // background: url(../../images/layers/tablet/tablet_portrait_background_01.png) no-repeat;
        background-attachment: fixed;
        background-size: contain !important;
        background-position: 50% 40px;
    }
    &.terms-privacy-main {
        background-image: none;
    }
}

@media (max-width: 450px) {
    body {
        background: url(../../images/layers/landing-bg-mobile.png) no-repeat #141414;
        background-position: calc(50% - -4px) calc(50% + 54px), 0px 120px;
        background-size: contain;
        background-position: 0 70px;
        background-attachment: fixed;
    }
}