@mixin orientation($direction) {
  $orientation-landscape: "(orientation:landscape)";
  $orientation-portrait: "(orientation:portrait)";
  @if $direction==landscape {
    @media #{$orientation-landscape} {
      @content;
    }
  }
  @if $direction==portrait {
    @media #{$orientation-portrait} {
      @content;
    }
  }
}

html,
body {
  min-height: 100%;
  height: auto;
  //overflow: hidden;
  padding: 0;
  margin: 0;
  width: 100%;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  @at-root body {
    display: flex;
    flex-direction: column;
  }
  *:focus,
  *.focus {
    box-shadow: none;
    outline: 0;
    outline: none;
  }
}

p {
  margin: 0;
  padding: 0;
}

body {
  display: flex;
  flex-direction: column;
  font-family: "Source Sans Pro";
}

.bg-navbar {
  background-color: rgba($color: $black, $alpha: 1);
}

.bg-dark {
  background-color: $header-bg;
}

header {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
}

.terms-privacy-main {
  h5 {
    color: $terms-privacy-question-color;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 2px;
  }
  .table {
    border-collapse: initial;
    border-radius: 6px;
    border-spacing: 0;
    .categories-parties,
    .personal-data {
      padding: 10px 47px;
    }
    .category-data {
      padding: 10px 31px;
    }
  }
  .table thead th {
    border-bottom-width: 1px;
  }
}

.categories-parties {
  width: 600px;
}

.privacy-list {
  li {
    position: relative;
    padding-left: 10px;
    &:before {
      content: "";
      width: 5px;
      height: 5px;
      background-color: #cccccc;
      border-radius: 50%;
      position: absolute;
      top: 10px;
      left: 0;
    }
  }
}

.terms-privacy-body-header {
  h1,
  h6 {
    color: $grey-1;
  }
  h6 {
    font-size: 18px;
  }
  h1 {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 6px;
  }
}

.terms-content {
  p {
    margin-bottom: 24px;
  }
}

section {
  padding-top: 50px;
  padding-bottom: 120px;
  // &:last-child {
  //     padding-top: 40px;
  //     padding-bottom: 80px;
  // }
}

// Utilities
.mt-20 {
  margin-top: 20px;
}

//main-content
.main-content {
  display: flex;
  flex: 1 1 100%;
}

select {
  -webkit-appearance: none;
}

.card {
  border-radius: 10px;
  // backdrop-filter: blur(3px);
  .image-container {
    min-height: 160px;
    padding-bottom: 20px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.value-proposition img {
  backdrop-filter: blur(3px);
}

.business-concept {
  .business-heading {
    + .row {
      > div:first-child,
      > div:last-child {
        .card-img-wrap {
          .card-img-top,
          .card-img-left {
            padding-bottom: 30px;
            @include media-breakpoint-down(sm) {
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }
  .card {
    .card-body {
      padding: 15px 30px 30px 30px;
    }
    .card-img-wrap {
      min-height: 140px;
      .card-img-left {
        padding: 30px 30px 0 30px;
        @include media-breakpoint-between(sm, md) {
          padding: 30px;
        }
      }
      .card-img-top {
        padding: 30px 90px 0 90px;
        @include media-breakpoint-between(sm, md) {
          padding: 30px;
        }
        @include media-breakpoint-down(sm) {
          padding: 30px 30px 0 30px;
        }
      }
    }
  }
  .featurette-heading {
    font-size: 30px;
    font-weight: bold;
    padding-top: 8px;
  }
  .lead {
    font-size: 15px;
  }
}

.w-90p {
  width: 90%;
}

.dark-bg {
  background-color: rgba(0, 0, 0, 0.2);
}

.navbar-dark {
  .navbar {
    padding: 0;
    .navbar-brand {
      font-weight: bold;
      font-size: 24px;
      color: $grey-1;
      @include media-breakpoint-between(sm, md) {
        font-size: 1.2rem !important;
      }
      @include media-breakpoint-between(md, lg) {
        font-size: 1.2rem !important;
      }
    }
    .navbar-nav {
      .nav-item {
        .nav-link {
          color: $grey-1;
          font-size: 18px;
          padding: 18px 22px 18px 22px;
          font-weight: 600;
          border-bottom: 3px solid transparent;
          text-align: center;
          transition: 0.3s all ease;
          display: table;
          margin: 0 auto;
        }
        &.active,
        &:hover {
          .nav-link {
            color: $primary;
            border-color: $primary;
          }
        }
        &:last-child {
          .nav-link {
            @include media-breakpoint-between(sm, md) {
              display: block;
            }
          }
        }
      }
    }
  }
}

h3 {
  font-size: 36px;
  font-weight: bold;
  color: $grey-2;
  margin-bottom: 0;
}

.about-heading {
  padding-bottom: 26px;
}

.business-heading {
  padding-bottom: 32px;
}

.value-heading {
  padding-bottom: 7px;
}

.contact-heading {
  padding-bottom: 52px;
}

.finoptsys-inc {
  padding: 25px 120px 32px 120px;
  .sass-platform {
    color: $primary;
    font-size: 24px;
    font-weight: bold;
  }
  .borne-clients {
    font-size: 24px;
    line-height: 32px;
  }
}

.abt-head {
  font-size: 24px;
  font-weight: bold;
  color: $primary;
}

.value-proposition {
  text-align: center;
  p {
    max-width: 900px;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 67px;
    font-size: 24px;
  }
}

h5 {
  font-size: 20px;
  font-weight: bold;
  color: $grey-2;
}

.fin-india {
  margin-top: 62px;
}

h4 {
  font-size: 24px;
  font-weight: bold;
  color: #a8a8a8;
}

input,
textarea {
  background-color: transparent !important;
  border-color: #666666;
  border-radius: 2px !important;
  resize: none;
}

.submit-btn {
  font-size: 18px;
  font-weight: 600;
  color: #d9f2e8;
}

footer {
  .navbar-brand {
    font-size: 24px;
    font-weight: bold;
    color: #999999;
  }
  p {
    a {
      font-size: 18px;
      padding-left: 40px;
      color: #b3b3b3;
      &:hover {
        color: $primary;
      }
    }
  }
}

.landing {
  min-height: 81vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .landing-text {
    text-align: center;
    position: relative;
    z-index: 1;
    div {
      font-size: 48px;
      font-weight: bold;
      color: #b3b3b3;
    }
    p {
      font-size: 24px;
      text-align: center;
    }
    button {
      border: none;
      font-weight: 600;
      margin-top: 17px;
      font-size: 18px;
      padding: 10px 55px;
      border-radius: 6px;
      color: #d9f2e8;
    }
  }
}

.form-control {
  resize: none;
  &.is-invalid {
    border-color: $red;
    background-image: none;
    padding-left: 12px;
  }
}

.error-message {
  color: $red;
  font-size: 12px;
  line-height: 15px;
}

label {
  line-height: 1;
  color: $gray-3;
}

.contact-us {
  .card-body {
    min-height: 560px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;
    .ok-btn {
      width: auto;
      padding: 10px 50px;
      line-height: 23px;
    }
  }
}

.captcha-wrapper {
  > div > div {
    width: 100% !important;
    text-align: center;
    @include media-breakpoint-down(sm) {
      overflow: auto;
    }
  }
  .rc-anchor-normal {
    width: 100% !important;
  }
}

.video-wrapper {
  margin: 60px 90px;
  position: relative;
  border: 1px solid $white;
  border-radius: 10px;
  overflow: hidden;
  @include media-breakpoint-down(sm) {
    margin: 30px 0 60px 0;
  }
  .iframe-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 64;
    padding: 0;
    img {
      min-width: 80px;
    }
  }
  .iframe-request-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 64;
  }
  .embed-responsive {
    border-radius: 10px;
  }
}

.embed-responsive-item {
  .ytp-button {
    display: none !important;
  }
}

*::-webkit-media-controls {
  display: none !important;
}

.navbar-dark .navbar {
  .navbar-nav {
    @include media-breakpoint-between(sm, md) {
      align-items: center;
    }
    .nav-item .nav-link {
      @include media-breakpoint-between(sm, md) {
        font-size: 13px !important;
        padding: 12px 9px !important;
      }
    }
  }
}

.portfolio-labels {
  display: flex;
  justify-content: center;
  margin: 50px 0 100px;
  text-align: center;
  display: flex;
  align-items: center;
  @include media-breakpoint-down(sm) {
    margin: 30px 0 50px;
  }
  .btn-group {
    display: flex;
    background-color: var(--primary);
    border-radius: 4px;
    padding: 1px;
    span {
      padding: 4px 20px;
      color: $white;
      background-color: rgb(56, 70, 65);
      border-radius: 3px;
      cursor: pointer;
      @include media-breakpoint-down(sm) {
        align-items: center;
        display: flex;
        padding: 4px 10px;
        text-align: center;
      }
      &.active {
        background-color: var(--primary);
      }
      &:hover {
        text-decoration: none;
      }
      + span {
        margin-left: 2px;
      }
    }
  }
}

.portfolio-container {
  flex-wrap: wrap;
  &.executive {
    &:before,
    &:after {
      content: "";
      width: 100%;
      order: 1;
    }
    .member:nth-child(n + 5) {
      order: 1;
    }
  }
  &.all {
    &:before,
    &:after {
      content: "";
      width: 100%;
      order: 1;
    }
    .member:nth-child(n + 5) {
      order: 1;
    }
    .member:nth-child(n + 9) {
      order: 2;
    }
  }
  .member {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 1 190px;
    width: 190px;
    margin-bottom: 80px;
    min-height: 187px;
    @include media-breakpoint-down(sm) {
      flex: 0 1 100%;
      margin-bottom: 50px;
      width: 100%;
    }
    img {
      height: 150px;
      width: 150px;
      border: 4px solid rgb(68, 68, 68);
      border-radius: 50%;
      margin-bottom: 10px;
      transition: all ease 0.3s;
      @include media-breakpoint-down(sm) {
        max-width: 80%;
        height: auto;
      }
      + span {
        font-size: 18px;
        @include media-breakpoint-down(sm) {
          font-size: 16px;
        }
      }
    }
    &:hover {
      img {
        transition: all ease 0.3s;
        box-shadow: 0 0 10px 3px rgba(255, 255, 255, 0.3);
      }
    }
  }
  .active-member-modal {
    padding: 55px 80px;
    background-color: #1d2021;
    border-radius: 3px;
    position: relative;
    z-index: 1;
    // transition: width 1s ease;
    @include media-breakpoint-down(md) {
      flex-direction: column;
      padding: 30px;
      position: fixed;
      top: 70px;
      overflow: scroll;
      height: 91%;
    }
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      background-color: transparent;
      position: fixed;
      left: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 0;
    }
    .left {
      padding-right: 40px;
      border-right: 1px solid #707070;
      margin-right: 50px;
      @include media-breakpoint-down(md) {
        border-right: 0;
        margin-right: 0;
        padding-right: 0;
      }
      .member {
        @include media-breakpoint-down(md) {
          margin: 30px auto;
        }
      }
    }
    img {
      + span {
        color: $grey-1;
      }
    }
    ul {
      padding-left: 0;
      list-style: none;
      li {
        margin-bottom: 1rem;
      }
    }
    .btn-close-member-modal {
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }
}

.portfolio-container {
  .active-member-modal {
    .member {
      flex: 0 1 210px;
      width: 210px;
    }
  }
}

.section-footer {
  min-height: auto;
}
