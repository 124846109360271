@include media-breakpoint-down(sm) {
    .container {
        padding-left: 20px;
        padding-right: 20px;
    }
    .navbar-brand {
        font-size: 20px !important;
    }
    section {
        padding-bottom: 40px;
    }
    h3 {
        font-size: 24px;
    }
    header {
        padding: 16px 20px 16px 27px;
    }
    .navbar-toggler {
        padding: 2px 3px;
        border-color: rgba(255, 255, 255, 0.53) !important;
    }
    .finoptsys-inc {
        padding: 18px 25px;
        .sass-platform,
        .borne-clients {
            font-size: 14px;
            line-height: 24px;
        }
    }
    .image-container {
        padding-bottom: 0 !important;
        min-height: 120px !important;
    }
    .abt-head {
        font-size: 20px;
        padding-bottom: 6px;
    }
    .max-width-md-266 {
        max-width: 266px;
        margin: 0 auto;
    }
    .featurette-heading {
        text-align: left;
        font-size: 20px !important;
        // padding-top: 24px !important;
    }
    .lead {
        text-align: center !important;
    }
    .value-proposition p {
        font-size: 14px;
    }
    .contact-heading {
        padding-bottom: 17px;
    }
    .fin-india {
        margin-top: 18px;
        &~p {
            margin-bottom: 55px;
        }
    }
    footer p a {
        padding-left: 0px;
        padding-right: 24px;
        font-size: 14px;
    }
    .landing {
        min-height: 60vh;
        .landing-text {
            div {
                font-size: 32px;
            }
            p {
                font-size: 16px;
            }
            button {
                font-size: 14px;
                padding: 8px 40px;
            }
        }
    }
    .card,
    .value-proposition img {
        backdrop-filter: blur(1px);
    }
}