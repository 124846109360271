@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
$body-bg: #141414;
$body-color: #CCCCCC;
$link-color: #1D8F61;
$terms-privacy-question-color: #00A867;
$red: #CC5C52;
$secondary-color: #666666;
$link-hover-color: darken($link-color, 15%) !default;
$header-bg: #212121 !important;

//Font
//
//Font family and font path
$font-regular: 'Source Sans Pro',
sans-serif;
;
$font-italic: 'Segoe Italic';
$font-light: 'Segoe UI Light';
$font-bold: 'Source Sans Pro',
sans-serif;
;
$font-semibold: 'Source Sans Pro',
sans-serif;
;


$font-family-base: $font-regular,
$font-light,
$font-italic,
$font-semibold,
$font-bold,
-apple-system,
"Helvetica Neue",
Roboto,
sans-serif; //-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$headings-font-family: $font-regular;

$page-heading-color: $link-color;

$base-spacing: 1rem !default;

//Custom Line height
$line-height-one : 1;
$line-height-one-and-half : 1.5;


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1174px) !default;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 24 !default;
$grid-gutter-width: 20px !default;



// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.


//
// Color system
//

// stylelint-disable
$white: #fff !default;
$black: #000 !default;


$primary: #1D8F61 !default; //#403460;//$blue !default;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.
$font-weight-base: normal;
$font-size-base: 1rem; // Assumes the browser default, typically `16px`

$font-size-button: 0.9375rem;
$line-height-base: 1.5 !default;

$font-weight-extra-light: 100;
$font-weight-semi-light: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;

//
$h1-font-size: 2.125rem; //rem(20px);
$h2-font-size: rem(18px);
$h3-font-size: rem(16px);
$h4-font-size: rem(15px);
$h5-font-size: rem(14px);
$h6-font-size: rem(12px);

$headings-font-weight: normal;
$headings-color: $page-heading-color;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1174px) !default;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1174px) !default;


// Card

$card-bg: rgba(0, 0, 0, .3) !default;
$card-spacer-y: 1rem !default;
$card-border-color: #666 !default;

$grey-1: #CCCCCC;
$grey-2: #B3B3B3;
$gray-3: #999999;


//Input

$input-border-color: $secondary-color;
$input-placeholder-color: $secondary-color;
$input-color: $body-color;